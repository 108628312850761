// Here you can add other styles
.custom {
  // padding-right: 55px;
  padding-bottom: 10px;
  align-self: auto;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-color: white;
  border-left-color: white;
  border-bottom-color: darkgray;
  border-right-color: darkgray;
  font-size: initial;
  padding-top: 4px;
  width: 240px;
}
.toaststyle {
  height: -webkit-fill-available;
  scroll-behavior: smooth;
}
.cardStyle {
  margin-left: 10px;
  margin-top: 10px;
}
.cardStyle2 {
  margin-top: 20px;
  margin-right: 10px;
  justify-content: flex-end;
}
.rdt_TableHeadRow {
  background: rgb(23, 38, 43) !important;
  color: white !important ;
}

.rdt_TableRow {
  background: white !important;
  color: black !important ;
}
.customHeader {
  background-color: rgb(23, 38, 43);
  color: white;
  font-size: 12px;
  font-weight: 500;
  height: 3rem;
}
.rdt_Pagination {
  // background: rgb(23, 38, 43) !important;
  // color: white !important ;
  // button
}

.item {
  height: 'auto';
  width: 100%;
  background-color: transparent;
}
.mapboxglstyle {
  // margin-right: 50%;
  // right: 40%;
  // top: 0;
}
input:focus,
input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.responsive-table {
  overflow-x: auto;
  width: 100%;
}

@media (max-width: 767px) {
  .responsive-table {
    display: block;
  }
  
  .responsive-table tbody,
  .responsive-table tr,
  .responsive-table th,
  .responsive-table td {
    display: block;
  }
  
  .responsive-table thead {
    display: none;
  }
  
  .responsive-table tr {
    margin-bottom: 1rem;
    border-bottom: 2px solid #dee2e6;
  }
  
  .responsive-table td {
    text-align: left;
    padding-left: 10px;
    border-bottom: none;
  }
  .react-data-table-component .rdt_TableHeader .rdt_TableCol {
    white-space: normal !important; /* Allow wrapping in header cells */
  }
  .responsive-table-admin {
    overflow-x: auto;
    /* width: 100%; */
    width: fit-content !important;
    .pb-0 {
      padding-bottom: 16px !important;
  }
}
}
